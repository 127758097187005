import React from "react"
import { graphql, Link } from "gatsby"

const Products = ({ data }) => {
  const { variants, groups, lines, areas, products } = data

  //console.log(categories)
  //console.log(variants)

  return (
    <>
      <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Produkt Bereiche:</h2>
        <ul className="mt-2">
          {areas.edges.map((area, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={area.node.fullSlug}
                >
                  <span className="w-20">{area.node.ID}</span>
                  <span className="w-20">{area.node.areaId}</span>
                  <span className="ml-6">{area.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Produkt Linien:</h2>
        <ul className="mt-2">
          {lines.edges.map((line, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={line.node.fullSlug}
                >
                  <span className="w-20">{line.node.externalKey}</span>
                  <span className="w-20">{line.node.areaId}</span>
                  <span className="ml-6">{line.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Produkt Gruppen:</h2>
        <ul className="mt-2">
          {groups.edges.map((group, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={group.node.fullSlug}
                >
                  <span className="w-20">{group.node.ID}</span>
                  <span className="w-20">{group.node.externalKey}</span>
                  <span className="ml-6">{group.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Produkte:</h2>
        <ul className="mt-2">
          {products.edges.map((products, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={products.node.fullSlug}
                >
                  <span className="w-20">{products.node.ID}</span>
                  <span className="w-20">{products.node.externalKey}</span>
                  <span className="ml-6">{products.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      <div className="my-4 mx-4">
        <h2 className="font-bold text-2xl">Varianten:</h2>
        <ul className="mt-2">
          {variants.edges.map((variant, index) => {
            return (
              <li key={index}>
                <Link
                  className="flex hover:text-primary"
                  to={variant.node.fullSlug}
                >
                  <span className="w-20">{variant.node.ID}</span>
                  <span className="w-20">{variant.node.externalKey}</span>
                  <span className="ml-6">{variant.node.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export const data = graphql`
  {
    variants: allContentServVariant {
      edges {
        node {
          name
          externalKey
          ID
          fullSlug
        }
      }
    }
    products: allContentServProduct {
      edges {
        node {
          name
          externalKey
          ID
          fullSlug
        }
      }
    }
    groups: allContentServProductGroup {
      edges {
        node {
          name
          ID
          externalKey
          fullSlug
        }
      }
    }
    lines: allContentServProductLine {
      edges {
        node {
          name
          ID
          areaId
          externalKey
          fullSlug
        }
      }
    }
    areas: allContentServProductArea {
      edges {
        node {
          name
          ID
          areaId
          externalKey
          fullSlug
        }
      }
    }
  }
`

export default Products
